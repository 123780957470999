// import external dependencies
import 'jquery';
import './bootstrap-select';
import '@fancyapps/fancybox/dist/jquery.fancybox';

// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_jquery-touchwipe.js'; import './autoload/_photoswipe.js'; import './autoload/_stick.js';

// custom scripts
import './recherche';
import './suche';
import './kf-custom';
import 'remodal/dist/remodal.min';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import nachrichten from './routes/nachrichten';
import singleNachrichten from './routes/single-nachrichten';
import singleArtikel from './routes/single-artikel';
import singleBand from './routes/single-band';
import singleAbo from './routes/single-abo';
import singleDossier from './routes/single-dossier';
import newsletter from './routes/newsletter';
import entdecken from './routes/entdecken';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Nachrichten
  nachrichten,
  // Nachrichten Single
  singleNachrichten,
  // Nachrichten Single
  singleArtikel,
  // Entdecken
  entdecken,
  // Single-Band
  singleBand,
  // Single-Abo
  singleAbo,
  // Single-Dossier
  singleDossier,
  // newsletterAbonnieren
  newsletter,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
