import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';

export default {
  init() {
    let elemTypes = [];
    let extractElement = false;
    let elementExtracted = false;
    let extractIndex = 9;
    let allElements = $('.postcontent').children();

    allElements.map((index, element) => {
      if (index < extractIndex && $(element).prop('class').includes('lazyload-img') ){
        extractElement = true;
      }
      elemTypes.push({
        className: element.className,
        element: element,
      })
    });

    let contentParser = {};
    contentParser.gallery = false;
    contentParser.prevIsFigure = false;
    contentParser.galleries = [];
    contentParser.tempGallery = [];

    $(elemTypes).each((idx, elem) => {
      if (extractElement && $(elem.element).prop('class').includes('lazyload-img') ){
        contentParser.tempGallery.push(elem);
        contentParser.galleries.push(contentParser.tempGallery);
        contentParser.tempGallery = [];
        contentParser.prevIsFigure = false;
        extractElement = false;
        elementExtracted = true;
      } else if ( $(elem.element).prop('class').includes('lazyload-img') ) {
        contentParser.tempGallery.push(elem);
        contentParser.prevIsFigure = true;
      } else {
        if (contentParser.prevIsFigure) {
          contentParser.galleries.push(contentParser.tempGallery);
          contentParser.tempGallery = [];
          contentParser.prevIsFigure = false;
        }
      }
    });

    contentParser.galleries.map(slideshow => {
      let wrapElems = slideshow.map(slideElement => {
        return slideElement.element;
      })
      if (elementExtracted){
        $(wrapElems).wrapAll('<div class="container content-slider row hide-arrows single-image" />');
        elementExtracted = false;
      }else{
        $(wrapElems).wrapAll('<div class="container content-slider row hide-arrows" />');
      }
    })

    setTimeout(() => {
      let prevIsHead = false;
      let firstHeadline = false;
      $('.postcontent').children().map((index, el) => {
        if (!firstHeadline) {
          if ($(el).is('h1') || $(el).is('h2') || $(el).is('h3') || $(el).is('h4') || $(el).is('h5')) {
            prevIsHead = true;
          } else {
            if (prevIsHead) {
              $(el).prepend($('<div style="height: 100px; width: 100%"></div>'));
              firstHeadline = true;
            }
          }
        }
      });
    }, 300);
  },
  finalize() {

    let topOffset = 70;

    function onScroll() {
      let y = $(document).scrollTop();
      let stickyHeight = $('.floating-issue').height();
      let trigger = $('.floating-trigger').offset().top - topOffset;
      let bottomEnd = $('.bottom-trigger').offset().top;
      let threshold = 300;

      if (y >= trigger) {
        $('.floating-sticky').addClass('fixed');
        $('.floating-sticky').width($('.top-anchor + .container').width());
      }
      else {
        $('.floating-sticky').removeClass('fixed');
        $('.floating-sticky').css({ 'width': 'auto' });
        $('.floating-sticky .floating-inner').css({
          'top': $('.static-sticky .floating-inner').outerHeight(),
        })
      }

      if ((y + stickyHeight + threshold) >= bottomEnd)
        $('.floating-sticky').addClass('is-bottom');
      else
        $('.floating-sticky').removeClass('is-bottom');
    }

    setTimeout(function () {
      $('.floating-sticky .floating-inner').css({
        'top': $('.static-sticky .floating-inner').outerHeight(),
      })
      $('.floating-sticky').width($('.top-anchor + .container').width());
      onScroll();
    }, 300);

    $(window).scroll(onScroll);
    $(window).resize(function () {
      $('.floating-sticky .floating-inner').css({
        'top': $('.static-sticky .floating-inner').outerHeight(),
      })
      $('.floating-sticky').width($('.top-anchor + .container').outerWidth());
    });



    let container = [];

    $('.postcontent').find('.wp-caption').each(function (idx, el) {
      $(el).attr('data-idx', parseInt(idx));
      let item = {
        src: $(this).find('img').attr('src'),
        h: $(this).find('img').attr('height'),
        w: $(this).find('img').attr('width'),
        title: $(this).find('figcaption').text(),
      };
      container.push(item);
    });

    let $pswp = $('.pswp')[0];

    $('.postcontent').find('.wp-caption').each(function (idx, el) {
      $(el).unbind('click').click(function(evt){
        evt.preventDefault();
        let options = {
          index: parseInt($(evt.currentTarget).attr('data-idx'), 10),
          bgOpacity: 0.85,
          showHideOpacity: true,
        };
        let gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
        gallery.init();
      })
    });


    $('#moreArticles .carousel-inner').not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      prevArrow: $('#moreArticles .slick-prev'),
      nextArrow: $('#moreArticles .slick-next'),
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $('#moreNachrichten .carousel-inner').not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      prevArrow: $('#moreNachrichten .slick-prev'),
      nextArrow: $('#moreNachrichten .slick-next'),
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    if ($('.content-slider').not('.single-image').length > 0)
      $('.content-slider').not('.single-image').each(function (idx, el) {
        $(el).not('.slick-initialized').slick({
          dots: true,
          infinite: false,
          speed: 300,
          // centerMode: true,
          // centerPadding: '60px',
          slidesToShow: 3,
          adaptiveHeight: true,
          prevArrow: '<div class="slick-prev">Previous</div>',
          nextArrow: '<div class="slick-next">Next</div>',
          responsive: [
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      })

    if ($('.content-slider.single-image').length > 0)
      $('.content-slider.single-image').each(function (idx, el) {
        $(el).not('.slick-initialized').slick({
          dots: true,
          infinite: false,
          speed: 300,
          // centerMode: true,
          // centerPadding: '60px',
          slidesToShow: 2,
          adaptiveHeight: true,
          prevArrow: '<div class="slick-prev">Previous</div>',
          nextArrow: '<div class="slick-next">Next</div>',
          responsive: [
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            }, {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
      })

    container = [];
    // Lazy load Content images on per API
    let Loader = {
      init() {
        let that = this;

        $('.postcontent').find('.content-slider').each( function() {
          $(this).find('.lazyload-img').each(function (idx, elem) {
            let dataId = $(elem).attr('data-idx');
            let postname = $(elem).attr('data-postname');

            that.loadImage(dataId, postname);
          });
        })
      },
      loadImage(dataId, postname){
        let that = this;

        $.getJSON(window.bilderAPI, { postname: postname }, function (html) {
          let element = $('.postcontent').find('.lazyload-img[data-idx="' + dataId + '"]');
          $(element).empty();
          $(element).removeClass('loading');
          $(element).html(html);

          $(element).click(function (event) {
            event.preventDefault();
            that.activate(event);
          });
        });
      },
      buildContainer(){
        $('.postcontent').find('.lazyload-img').each(function (idx, el) {
          $(el).attr('data-idx', idx);
          let item = {
            src: $(this).find('img').attr('src'),
            h: $(this).find('img').attr('height'),
            w: $(this).find('img').attr('width'),
            title: $(this).find('figcaption').text() || '',
          };
          container.push(item);
        });
      },
      activate(event){
        // Define object and gallery options$('.postcontent').find('figure').each(function (idx, el) {
        this.buildContainer();

        let $pswp = $('.pswp')[0];
        let options = {
          index: parseInt($(event.currentTarget).attr('data-idx'), 10),
          bgOpacity: 0.85,
          showHideOpacity: true,
        };

        let gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
        gallery.init();
      },
    }

      setTimeout(() => {
        Loader.init();
      }, 300);
  },
}
