function debounce(callback, delay) {
  var timeout
  return function () {
    var args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      callback.apply(this, args)
    }.bind(this), delay)
  }
}

// function decodeEntities(input) {
//   var y = document.createElement('textarea');
//   y.innerHTML = input;
//   return y.value;
// }

(function () {
  let threshold = 1000;

  let Spinner = {
    elem: $('.tab-content'),
    show() {
      this.elem.addClass('loading');
      $('#personen-text-query').prop('disabled', true);
      $('#inst-text-query').prop('disabled', true);
      $('#grossauss-text-query').prop('disabled', true);
    },
    hide() {
      this.elem.removeClass('loading');
      $('#personen-text-query').prop('disabled', false);
      $('#inst-text-query').prop('disabled', false);
      $('#grossauss-text-query').prop('disabled', false);
    },
  }

  // Person Search
  let PersonSearch = {
    isSearching: false,
    ajaxSearch(key) {
      var that = this;

      Spinner.show();
      this.isSearching = true;

      let data = $('.personen-form').serializeArray().reduce(
        function (a, x) { a[x.name] = x.value; return a; }, {}
      );

      data.issue = $('.personen-form').find('[name="issue"]').val();
      data.s = key;
      data.lastname = '';

      $.ajax({
        url: window.personenAPI,
        type: "GET",
        data: data,
        timeout: 30000,
        success: function (html) {
          $('.listing-holder.personen').empty();
          $('.listing-holder.personen').html(html);
          that.afterSearch();

          Spinner.hide();
          this.isSearching = false;
        },
        error: function(){
          Spinner.hide();
          this.isSearching = false;
        },
      });
    },
    deeperSearch(letter, ids) {
      var that = this;

      Spinner.show();
      this.isSearching = true;

      let data = $('.personen-form').serializeArray().reduce(
        function (a, x) { a[x.name] = x.value; return a; }, {}
      );

      data.issue = $('.personen-form').find('input[name="issue"]').val();

      data.starts_with = letter;
      data.ids = ids;
      delete data.s;

      $.ajax({
        url: window.personenAPI,
        type: "GET",
        data: data,
        timeout: 30000,
        success: function (html) {
          let container = $('.letter-row[data-letter="' + letter + '"]');
          $('.listing-holder.personen').find('.toggle-more[data-letter="' + letter + '"]').parent().remove();

          // $('.listing-holder.personen').empty();
          container.append(html);
          that.afterSearch();

          Spinner.hide();
          this.isSearching = false;

          $('body').on('click', '.listing-holder.personen .toggle-more', function(evt){
            evt.preventDefault();
            let letter = $(this).attr('data-letter');
            let ids = $(this).attr('data-in');
            
            that.deeperSearch(letter, ids);
          })
        },
        error: function () {
          Spinner.hide();
          this.isSearching = false;
        },
      });
    },
    afterSearch() {

    },
    onLoad() {
      this.ajaxSearch('');
    },
    init() {
      var that = this;
      $('#personen-text-query').on('input', debounce(function () {
        if (!this.isSearching) {
          that.ajaxSearch($(this).val());
        }
      }, threshold));

      $('body').on('click', '.listing-holder.personen .toggle-more', function(evt){
        evt.preventDefault();
        let letter = $(this).attr('data-letter');
        let ids = $(this).attr('data-in');
        
        that.deeperSearch(letter, ids);
      })

      $('#personen-submit-query').on('click', function (event) {
        event.preventDefault();
        that.ajaxSearch($('#personen-text-query').val());
      });

      $('.personen-form').find('select').each(function(){
        $(this).on('changed.bs.select', function (event) {
          event.preventDefault();
          that.ajaxSearch($('#personen-text-query').val());
        });
      });
    },
  }
  PersonSearch.init();
  PersonSearch.onLoad();


  // Inst Search
  let InstSearch = {
    isSearching: false,
    ajaxSearch(key) {
      var that = this;

      Spinner.show();
      this.isSearching = true;

      let data = $('.inst-form').serializeArray().reduce(
        function (a, x) { a[x.name] = x.value; return a; }, {}
      );

      data.issue = $('.inst-form').find('input[name="issue"]').val();
      data.s = key;

      $.ajax({
        url: window.institutionenAPI,
        type: "GET",
        data: data,
        timeout: 30000,
        success: function (html) {
          $('.listing-holder.inst').empty();
          $('.listing-holder.inst').html(html);
          that.afterSearch();

          Spinner.hide();
          this.isSearching = false;
        },
        error: function () {
          Spinner.hide();
          this.isSearching = false;
        },
      });
    },
    afterSearch() {
      $('.inst-artikel .inst-row').each(function(){
        var that = this;
        $(this).find('.toggle-more').click(function (event) {
          event.preventDefault();
          $(that).find('.expand-item.hidden').removeClass('hidden');
          $(this).parent().remove();
        });
      })
    },
    onLoad() {
      this.ajaxSearch('');
    },
    init() {
      var that = this;
      $('#inst-text-query').on('input', debounce(function () {
        if (!this.isSearching) {
          that.ajaxSearch($(this).val());
        }
        // filterPersonen($(this).val());
      }, threshold));

      $('#inst-submit-query').on('click', function (event) {
        event.preventDefault();
        that.ajaxSearch($('#inst-text-query').val());
      });

      $('.inst-form').find('select').each(function () {
        var ref = this;

        $(this).on('changed.bs.select', function (event) {
          event.preventDefault();

          if ($(ref).attr('id') === 'research-filter-country') {
            if($(this).val() !== ''){
              $('[data-id="research-filter-place"]').attr('disabled', true);
              $('#research-filter-place').val(1);
            } else {
              $('[data-id="research-filter-place"]').attr('disabled', false);
            }
          }else


          if ($(ref).attr('id') === 'research-filter-place') {
            if ($(this).val() !== '') {
              $('[data-id="research-filter-country"]').attr('disabled', true);
              $('#research-filter-country').val(1);
            } else {
              $('[data-id="research-filter-country"]').attr('disabled', false);
            }
          }

          that.ajaxSearch($('#inst-text-query').val());
        });
      });

      this.afterSearch();
    },
  }

  InstSearch.init();
  InstSearch.onLoad();


  // Grossauss Search
  let GrossaussSearch = {
    isSearching: false,
    ajaxSearch(key) {
      var that = this;
      
      Spinner.show();
      this.isSearching = true;

      let data = $('.grossauss-form').serializeArray().reduce(
        function (a, x) { a[x.name] = x.value; return a; }, {}
      );

      data.issue = $('.grossauss-form').find('input[name="issue"]').val();
      data.s = key;

      $.ajax({
        url: window.grossaussAPI,
        type: "GET",
        data: data,
        timeout: 30000,
        success: function (html) {
          $('.listing-holder.grossauss').empty();
          $('.listing-holder.grossauss').html(html);
          that.afterSearch();

          Spinner.hide();
          this.isSearching = false;
        },
        error: function () {
          Spinner.hide();
          this.isSearching = false;
        },
      });
    },
    afterSearch() {

    },
    onLoad () {
      this.ajaxSearch('');
    },
    init() {
      var that = this;
      $('#grossauss-text-query').on('input', debounce(function () {
        if (!this.isSearching) {
          that.ajaxSearch($(this).val());
        }
        // filterPersonen($(this).val());
      }, threshold));

      $('#grossauss-submit-query').on('click', function (event) {
        event.preventDefault();
        that.ajaxSearch($('#grossauss-text-query').val());
      });

      $('.grossauss-form').find('select').each(function () {
        $(this).on('changed.bs.select', function (event) {
          event.preventDefault();
          that.ajaxSearch($('#grossauss-text-query').val());
        });
      });

      this.afterSearch();
    },
  }

  GrossaussSearch.init();
  GrossaussSearch.onLoad();

})();
