export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    let body = $("html, body");

    $('.kf-categories-repeater').find('a').click(function (event) {
      event.preventDefault();

      let anchor = $(this).prop('href').split('#')[1];
      body.stop().animate({ scrollTop: $(`#${anchor}`).offset().top - 100 }, 400);
    })

    $(document).ready(function() {
      let searchParams = new URLSearchParams(window.location.search)

      if (searchParams.has('blick-ins-heft')) {
        $('[data-fancybox]')[0].click();
      }
    })
  },
};
