(function () {
  // Bilder Suche
  let BilderSuche = {
    sort(sorting) {
      // Spinner.hide();

      let elements = $('.kf-mini-images-item .grid-image');
      let appendTo = '.kf-mini-images-item';

      if (sorting === 'alpha') {
        elements.sort(function (a, b) {
          return $(a).attr('data-alpha').toLowerCase() > $(b).attr('data-alpha').toLowerCase();
        }).appendTo(appendTo);
      }

      if (sorting === 'alpha-invert') {
        elements.sort(function (a, b) {
          return $(a).attr('data-alpha').toLowerCase() < $(b).attr('data-alpha').toLowerCase();
        }).appendTo(appendTo);
      }

      if (sorting === 'asc') {
        elements.sort(function (a, b) {
          return $(a).attr('data-band') > $(b).attr('data-band');
        }).appendTo(appendTo);
      }

      if (sorting === 'desc') {
        elements.sort(function (a, b) {
          return $(a).attr('data-band') < $(b).attr('data-band');
        }).appendTo(appendTo);
      }
      
    },
    filter(filter) {
      let elements = $('.kf-mini-images-item .grid-image');

      if(filter === ''){
        $(elements).each(function () {
          $(this).removeClass('deselect');
        });  
        return false;
      }

      $(elements).each(function () {
        $(this).addClass('deselect');
      });

      $(elements).each(function(){
        if ($(this).attr('data-band') === filter){
          $(this).removeClass('deselect');
        }
      })
    },
    buildFilters() {
      let select = $('#search-image-filter-issue');
      select.empty();

      let elements = $('.kf-mini-images-item .grid-image');
      let options = [];
      let bd = [];

      // get all options from results images
      elements.each(function(){
        let el = {
          value: $(this).attr('data-band'),
          text: 'Bd. ' + $(this).attr('data-band'),
        }

        if ( !bd.includes(el.value) ){
          bd.push(el.value);
          options.push(el);
        }
      })

      // add default
      select.append(
        $('<option value="" selected>Band wählen</option>')
      )

      options.sort(function (a, b) {
        return a.value < b.value;
      })
      // Add options
      options.map(option => {
        select.append(
          $('<option value="' + option.value + '">' + option.text + '</option>')
        )
      })

      select.selectpicker();
    },
    init() {
      let that = this;

      $('#search-image-filter-issue').on('changed.bs.select', function (event) {
        event.preventDefault();
        that.filter($(this).val());
      });

      $('#search-image-sorting').on('changed.bs.select', function (event) {
        event.preventDefault();
        that.sort($(this).val());
      });

      this.buildFilters();
      this.sort();
    },
  }

  if ($('body').hasClass('template-suche-bilder') ){
    BilderSuche.init();
  }

  if ($('body').hasClass('single-person')) {
    BilderSuche.init();
  }

})();
