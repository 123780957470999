import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js';
import 'slick-carousel/slick/slick.js';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    $(document).ready(function() {
        if ($('.content-slider').not('.single-image').length > 0)
          $('.content-slider').not('.single-image').each(function (idx, el) {
            $(el).not('.slick-initialized').slick({
              dots: true,
              infinite: false,
              speed: 300,
              // centerMode: true,
              // centerPadding: '60px',
              slidesToShow: 3,
              adaptiveHeight: true,
              prevArrow: '<div class="slick-prev">Previous</div>',
              nextArrow: '<div class="slick-next">Next</div>',
              responsive: [
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                }, {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          })

        if ($('.content-slider.single-image').length > 0)
          $('.content-slider.single-image').each(function (idx, el) {
            $(el).not('.slick-initialized').slick({
              dots: true,
              infinite: false,
              speed: 300,
              // centerMode: true,
              // centerPadding: '60px',
              slidesToShow: 2,
              adaptiveHeight: true,
              prevArrow: '<div class="slick-prev">Previous</div>',
              nextArrow: '<div class="slick-next">Next</div>',
              responsive: [
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                }, {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          })

        $('#moreArticles .carousel-inner').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          prevArrow: $('#moreArticles .slick-prev'),
          nextArrow: $('#moreArticles .slick-next'),
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });

        $('#moreNachrichten .carousel-inner').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          prevArrow: $('#moreNachrichten .slick-prev'),
          nextArrow: $('#moreNachrichten .slick-next'),
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });

        $('#moreRecommendations .carousel-inner').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          prevArrow: $('#moreRecommendations .slick-prev'),
          nextArrow: $('#moreRecommendations .slick-next'),
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            }, {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    });

    let container = [];
    // Lazy load Content images on per API
    let Loader = {
      init() {
        let that = this;

        $('.postcontent').find('.content-slider').each( function() {
          $(this).find('.lazyload-img').each(function (idx, elem) {
            let dataId = $(elem).attr('data-idx');
            let postname = $(elem).attr('data-postname');

            that.loadImage(dataId, postname);
          });
        })
      },
      loadImage(dataId, postname){
        let that = this;

        $.getJSON(window.bilderAPI, { postname: postname }, function (html) {
          let element = $('.postcontent').find('.lazyload-img[data-idx="' + dataId + '"]');
          $(element).empty();
          $(element).removeClass('loading');
          $(element).html(html);

          $(element).click(function (event) {
            event.preventDefault();
            that.activate(event);
          });
        });
      },
      buildContainer(){
        $('.postcontent').find('.lazyload-img').each(function (idx, el) {
          $(el).attr('data-idx', idx);
          let item = {
            src: $(this).find('img').attr('src'),
            h: $(this).find('img').attr('height'),
            w: $(this).find('img').attr('width'),
            title: $(this).find('figcaption').text() || '',
          };
          container.push(item);
        });
      },
      activate(event){
        // Define object and gallery options$('.postcontent').find('figure').each(function (idx, el) {
        this.buildContainer();

        let $pswp = $('.pswp')[0];
        let options = {
          index: parseInt($(event.currentTarget).attr('data-idx'), 10),
          bgOpacity: 0.85,
          showHideOpacity: true,
        };

        let gallery = new PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
        gallery.init();
      },
    }

      setTimeout(() => {
        Loader.init();
      }, 300);
  },
};
