// eslint-disable-next-line
import featherlight from 'featherlight';

/**
 * jQuery Plugin to obtain touch gestures from iPhone, iPod Touch and iPad, should also work with Android mobile phones (not tested yet!)
 * Common usage: wipe images (left and right to show the previous or next image)
 *
 * @author Andreas Waltl, netCU Internetagentur (http://www.netcu.de)
 * @version 1.0 (15th July 2010)
 */
(function ($) {
  $.fn.touchwipe = function (settings) {
    var config = {
      min_move_x: 20,
      wipeLeft: function () {
        alert("left");
      },
      wipeRight: function () {
        alert("right");
      },
      preventDefaultEvents: true,
    };

    if (settings) {
      $.extend(config, settings);
    }
    var that = $(this);

    this.each(function () {
      var startX;
      var isMoving = false;

      function cancelTouch() {
        that[0].removeEventListener('touchmove', onTouchMove);
        startX = null;
        isMoving = false;
      }

      function onTouchMove(e) {
        if (config.preventDefaultEvents) {
          e.preventDefault();
        }
        if (isMoving) {
          var x = e.touches[0].pageX;
          var dx = startX - x;
          if (Math.abs(dx) >= config.min_move_x) {
            cancelTouch();
            if (dx > 0) {
              config.wipeLeft();
            } else {
              config.wipeRight();
            }
          }
        }
      }

      function onTouchStart(e) {
        if (e.touches.length == 1) {
          startX = e.touches[0].pageX;
          isMoving = true;
          that[0].addEventListener('touchmove', onTouchMove, false);
        }
      }

      this.addEventListener('touchstart', onTouchStart, false);
    });

    return this;
  };

})(jQuery);

function getUrlParameter(name) {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default {
  init() {
    $('#titleArticles .carousel-inner').slick({
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      prevArrow: $('#titleArticles .carousel-control-prev, #titleArticles .slick-prev'),
      nextArrow: $('#titleArticles .carousel-control-next, #titleArticles .slick-next'),
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    let maxslides = 3;
    let slidesCount = $('#titleArticles .slick-slide').length;

    $('#titleArticles .carousel-inner').on('afterChange', function (event, slick, currentSlide) {
      if (currentSlide === 0) {
        $('#titleArticles .slick-dots span').text('1');
      } else {
        let count = Math.ceil((currentSlide / maxslides))
        $('#titleArticles .slick-dots span').text(count + 1);
      }
      $('#titleArticles .slick-dots div').text(Math.ceil(slidesCount / maxslides));
    });

    $(window).resize(function () {
      let w = $(window).width();
      if (w < 800) {
        if (w < 480) {
          maxslides = 1
        } else {
          maxslides = 2
        }
      } else {
        maxslides = 3
      }

      $('#titleArticles .slick-dots div').text(Math.ceil(slidesCount / maxslides));
    }())

    // new popup

    let $popit = $('.popit'),
      $popitNotScroll = $('.popit:not(.scroll)'),
      $popitScroll = $('.popit.scroll'),
      $popitClose = $('.popit .popit-close'),
      $popitMinimize = $('.popit .popit-minimize'),
      $popitExpand = $('.popit .popit-expand'),
      $popupExpandIntext = $('.popup-expand-intext'),
      $popitLeakyExpander = $('.popit-leaky-expander'),
      // popitCollapsed = false,
      isLeakyPopit = $('.popit').hasClass('has-leaky'),
      popitExpanded = false;

    if ($popitScroll) {
      $(document).scroll(() => {
        let maincontent = $('.maincontent');
        if (maincontent.length === 0 || maincontent.offset().top < window.pageYOffset + 200) {
          $popitScroll.addClass('showing');
        }
      });
    }

    if ($popitNotScroll) {
      setTimeout(() => {
        $popitNotScroll.addClass('showing');
      }, 1000);
    }

    $popitClose.click(function () {
      if (isLeakyPopit) {
        $popit.addClass('hidden');
      } else {
        $popit.addClass('hidden');
      }
      // popitCollapsed = true;
    })

    $popupExpandIntext.click(function () {
      if (!popitExpanded) {
        $popit.addClass('expanded');
        $popitExpand.removeClass('inverted');
        popitExpanded = true;
      } else {
        $popitExpand.addClass('inverted');
        $popit.removeClass('expanded');
        popitExpanded = false;
      }
    })

    $popitExpand.click(function () {
      if (!popitExpanded) {
        $popit.addClass('expanded');
        $popitExpand.removeClass('inverted');
        popitExpanded = true;
      } else {
        $popitExpand.addClass('inverted');
        $popit.removeClass('expanded');
        popitExpanded = false;
      }
    })

    $popitLeakyExpander.click(function () {
      $popitNotScroll.removeClass('hidden');
    });

    $popitMinimize.click(function () {
      $popit.removeClass('expanded');
      $popit.addClass('hidden');
      popitExpanded = false;
    });

    $('#issueCarousel .carousel-inner').not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      prevArrow: $('#issueCarousel .slick-prev'),
      nextArrow: $('#issueCarousel .slick-next'),
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // show recherche form
    var $rechercheSearch = $('.kf-top-bar-search.research');
    if ($rechercheSearch) {
      setTimeout(() => {
        $rechercheSearch.addClass('show');
      }, 750);
    }

    // redirect to home on bar-click
    $('.home-link').click(function (event) {
      event.preventDefault();
      window.location = '/';
    })

    // news caroussel
    let $newsSlick = $('#newsCarousel .carousel-inner').slick({
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: true,
      draggable: true,
      prevArrow: $('#newsCarousel .carousel-control-prev'),
      nextArrow: $('#newsCarousel .carousel-control-next'),
      responsive: [
        {
          breakpoint: 800,
          settings: {
            rows: 2,
            slidesPerRow: 2,
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        }, {
          breakpoint: 480,
          settings: {
            rows: 4,
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    let slidesPerPage = 4;

    $newsSlick.on('init reInit afterChange', function (event, slick, currentSlide) {
      let maxPages = Math.ceil(slick.slideCount / slidesPerPage);
      let currentPage = Math.ceil(currentSlide / slidesPerPage) + 1;

      if (slick.activeBreakpoint === 480 || slick.activeBreakpoint === 800) {
        maxPages = slick.slideCount;
        currentPage = currentSlide + 1;
      }

      $('#newsCarousel .carousel-caption.slick-dots > span').text(currentPage + '/' + maxPages);
    });


    // JavaScript to be fired on all pages
    let body = $("html, body");

    // timeline click->scroll
    let lastyear = 2018;
    $('.timeline .year').click(function (evt) {
      let href = $(evt.currentTarget).attr('data-year');
      let $el = $('#year-' + href);
      if (lastyear > href) {
        body.stop().animate({scrollTop: $el.offset().top - 120}, 3000);
      } else {
        body.stop().animate({scrollTop: $el.offset().top - 190}, 3000);
      }
      lastyear = href;
    });

    let topOffset = 80;
    let lastPosition = 0;

    function onScroll() {
      let y = $(document).scrollTop();
      let trigger = $('.timeline-trigger').offset().top - topOffset;
      // let stickyHeight = $('.timeline-holder').height();
      // let bottomEnd = $('.bottom-trigger').offset().top;
      // let threshold = 300;

      if (y >= trigger) {
        $('.timeline-sticky').addClass('fixed');
        if (y > lastPosition) {
          $('.timeline-sticky').removeClass('timeline-scroll-custom');
        } else {
          $('.timeline-sticky').addClass('timeline-scroll-custom');
        }
      } else {
        $('.timeline-sticky').removeClass('fixed');
        if (y > lastPosition) {
          $('.timeline-sticky').removeClass('timeline-scroll-custom');
        } else {
          $('.timeline-sticky').addClass('timeline-scroll-custom');
        }
      }


      // if ((y + stickyHeight + threshold) >= bottomEnd)
      //   $('.floating-sticky').addClass('is-bottom');
      // else
      //   $('.floating-sticky').removeClass('is-bottom');

      $('.timeline-sticky').width($('.timeline-holder').width());
      lastPosition = y;
    }

    if ($('body').hasClass('template-archiv')) {
      setTimeout(onScroll, 200);
      $(window).scroll(onScroll);
    }

    $('#header-search-form').submit(function (event) {
      event.preventDefault();

      let query = $(this).find('[name="query"]').val();
      let pathname = $(this).attr('action');
      let issue = getUrlParameter('issue');
      let parameters = {query, issue};
      let searchUrl = `${pathname}?${$.param(parameters)}`;

      window.location.href = searchUrl;
    });

    $('#header-recherche-form').submit(function (event) {
      event.preventDefault();

      let query = $(this).find('[name="query"]').val();
      let pathname = $(this).attr('action');
      let issue = getUrlParameter('issue');
      let parameters = {query, issue};
      let searchUrl = `${pathname}?${$.param(parameters)}`;

      window.location.href = searchUrl;
    });
  },
  finalize() {


    $('.expand-login-text').click(function () {
      $('.expand-login-holder').toggle();
      $('.expand-login-text').toggleClass('show');
    })

    $('.bestell-form').each(function () {

      var formEl = $(this).find('.gform_wrapper');
      var formID = formEl.attr('id').split('_').pop();
      window.formId = formID;


      $('.gsection.breaker').prevAll().wrapAll('<div class="column-left"/>');
      $('.column-left').children().each(function (i, li) {
        $('.column-left').prepend(li)
      })
      $('.gsection.breaker').nextAll().wrapAll('<div class="column-right"/>');
      $('.gsection.breaker').remove();

      $('.gfield_select').selectpicker();


      $('.btn-target').click(function (event) {
        event.preventDefault();
        var agreement_form = document.getElementById("agreement_form");
        var agreement_form_invalid = document.getElementById("agreement_form_invalid");

        if (agreement_form.checked == true){
          agreement_form_invalid.style.display = "none";
          $('#gform_submit_button_' + formID).trigger('click');
        } else {
          agreement_form_invalid.style.display = "block";
        }
      })

      var inlandAbo = $('select[name="input_40"] option').eq(0).val().split('|');
      var auslandAbo = $('select[name="input_40"] option').eq(1).val().split('|');
      var preise = {
        inland: {
          name: inlandAbo[0],
          preis: inlandAbo[1],
        },
        ausland: {
          name: auslandAbo[0],
          preis: auslandAbo[1],
        },
      }

      var discount = 0;
      var toggledLieferAd = false;

      // set preis for form
      $('.abo-titel').text(preise.inland.name);
      $('.abo-preis').text((parseFloat((preise.inland.preis - discount)).toFixed(2) + ' €').replace('.', ','));

      $('input[name="input_44"]').attr('value', preise.inland.name);
      $('input[name="input_42"]').attr('value', (parseFloat((preise.inland.preis - discount)).toFixed(2) + ' €').replace('.', ','));

      $('.rechnung_gleich_liefer_adr input').click(function () {
        toggledLieferAd = !toggledLieferAd;
        $('input[name="input_53"]').attr('value', $('select[name="input_54"]').val());
        setNewPreis();
      })

      $('input[name="input_52"]').attr('value', $('select[name="input_50"]').val());
      $('input[name="input_54"]').attr('value', $('select[name="input_53"]').val());

      function setNewPreis() {
        var lieferLand, land;
        // if (window.formId === '22') {
        //   // change order to respect custom settings on this form
        //     lieferLand = $('select[name="input_54"]').attr('value');
        //     land = $('select[name="input_54"]').attr('value');
        // } else {
        land = $('input[name="input_52"]').attr('value');
        lieferLand = $('input[name="input_53"]').attr('value');
        // }

        window.lieferLand = lieferLand;

        var preisIn = parseFloat((preise.inland.preis - discount)).toFixed(2).replace('.', ',');
        var preisAus = parseFloat((preise.ausland.preis - discount)).toFixed(2).replace('.', ',');

        if (!toggledLieferAd) {
          if (land === 'Deutschland') {
            $('.abo-titel').text(preise.inland.name);
            $('.abo-preis').text(preisIn + ' €');
            $('input[name="input_42"]').attr('value', preisIn + ' €');
          } else {
            $('.abo-titel').text(preise.ausland.name);
            $('.abo-preis').text(preisAus + ' €');
            $('input[name="input_42"]').attr('value', preisAus + ' €');
          }
        } else {
          if (lieferLand === 'Deutschland') {
            $('.abo-titel').text(preise.inland.name);
            $('.abo-preis').text(preisIn + ' €');
            $('input[name="input_42"]').attr('value', preisIn + ' €');
          } else {
            $('.abo-titel').text(preise.ausland.name);
            $('.abo-preis').text(preisAus + ' €');
            $('input[name="input_42"]').attr('value', preisAus + ' €');
          }
        }
      }

      $('select[name="input_50"]').on('hidden.bs.select', function (event) {
        $('input[name="input_52"]').attr('value', $(event.target).val());
        setNewPreis();
      });

      $('select[name="input_54"]').on('hidden.bs.select', function (event) {
        $('input[name="input_53"]').attr('value', $(event.target).val());
        setNewPreis();
      });

      var formGutscheine = window.discounts.filter(function (gutscheinArr) {
        return gutscheinArr.formularid === window.formId;
      })


      formGutscheine[0].gutscheine.forEach(function (gutscheineArr) {
        gutscheineArr['gutscheincodeArr'] = gutscheineArr.gutscheincode.split(',');
      })

      function checkGutschein(code) {
        discount = -1;

        formGutscheine[0].gutscheine.forEach(function (gutscheineArr) {
          if (gutscheineArr.gutscheincodeArr.includes(code)) {
            discount = parseFloat(gutscheineArr.dicount);
          }
        })
        return discount;
      }

      function getGutscheinText(code) {
        var text = '';

        formGutscheine[0].gutscheine.forEach(function (gutscheineArr) {
          if (gutscheineArr.gutscheincodeArr.includes(code)) {
            text = gutscheineArr.gutscheintext;
          }
        })
        return text;
      }

      window.formGutscheine = formGutscheine;

      $('input[name="input_24"]').on('input', function () {
        var code = $(this).val();
        if (checkGutschein(code) !== -1) {
          discount = checkGutschein(code);
          $(this).parent().addClass('code-valid');
          var text = getGutscheinText(code);
          if (text !== '') {
            $('.abo-card > .row:first ul').append('<li class="gutscheintext">' + text + '</li>');
          }
        } else {
          discount = 0;
          $(this).parent().removeClass('code-valid');
          $('.abo-card > .row:first ul li.gutscheintext').remove();
        }

        if (code === 'KFOnline22') {
          $('#field_44_3 .gfield_radio li:nth-child(1)').hide();
          $('#field_44_3 #choice_44_3_1').prop( "checked", true );
          $('#field_44_3 .gfield_radio li:nth-child(3)').hide();
        }

        setNewPreis();
      })

      // $('input[name="input_24"]').on('input', function(){
      //   var code = $(this).val();
      //   if( gutscheine.includes(code) ){
      //     discount = 10.0;
      //     $(this).parent().addClass('code-valid');
      //   } else {
      //     discount = 0;
      //     $(this).parent().removeClass('code-valid');
      //   }
      //   setNewPreis();
      // })

      //parseurl
      var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;

        for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
          }
        }
        return false;
      };

    // fire Gutschein
      if(getUrlParameter('vouchercode')){
        $('input[name="input_24"]').val(getUrlParameter('vouchercode'));
        $('input[name="input_24"]').trigger('input');
      }
    });

    let lbOptions = {};
    let $lbContent = $('[data-lightbox-content]');
    window.triggerSuccess = function () {
      $.featherlight($lbContent, lbOptions)
      var current = $.featherlight.current();
      current.open();
      $('.featherlight').find('a.kf-btn-white').click(function (event) {
        event.preventDefault();
        current.close();
      })
    }

    // custom scroll transition for nav
    let navbar = $('#navbar-main');

    $(window).scroll(function () {
      let position = jQuery(this).scrollTop();
      if (position < 45) {
        navbar.addClass('initial-state');
      } else {
        navbar.removeClass('initial-state');
      }
    });

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      navbar.addClass('mobile-nav');
    }

    // trigger login link in nav
    $('#nav-login-btn').click(function (event) {
      if ($(this).hasClass('active')) {
        event.preventDefault();
      } else {
        return true;
      }

      if (!$('.navbar').hasClass('show-login')) {
        $('.navbar').addClass('show-login');
        $('.navbar').removeClass('show-search');
      } else {
        $('.navbar').removeClass('show-login');
      }

      $(this).toggleClass('toggled');
    })

    // trigger search link in nav
    $('#nav-search-btn').click(function (event) {
      event.preventDefault();

      if (!$('.navbar').hasClass('show-search')) {
        $('.navbar').addClass('show-search');
        $('.navbar').removeClass('show-login');
      } else {
        $('.navbar').removeClass('show-search');
      }

      $(this).toggleClass('toggled');
    })

    $('[data-fancybox]').fancybox({
      toolbar: false,
      smallBtn: true,
      iframe: {
        preload: false,
      },
      afterLoad: function () {
        if ($('[data-fancybox]').attr('href').includes('big')) {
          $('.fancybox-content').addClass('fancybox-big');
        }
      },
    })

    /**
     * --- Anzeigenbuchung ---
     */
    $('#gform_fields_48 .gfield_select').selectpicker();

    /* Mehrfachbuchungen */
    const multiple_price = $('span.ginput_total_48');
    const mehrfachbuchung_input = $('#input_48_41');
    const neukunden_checkbox = $('#choice_48_23_1');
    const bannerschaltung_checkbox = $('#choice_48_11_0,#choice_48_11_1,#choice_48_11_2');

    // TODO all other selectors
    $('#input_48_6, #input_48_9, #input_48_2, #input_48_1').on('change', function () {
      resetPrice();
      mehrfachbuchung_input.find(':checked').prop('checked', false);
      neukunden_checkbox.find(':checked').prop('checked', false);
    })

    mehrfachbuchung_input.on('change', function () {
      resetPrice();
      changeAnzeigenPrice();
    })

    neukunden_checkbox.on('change', function () {
      resetPrice();
      changeAnzeigenPrice();
    })

    bannerschaltung_checkbox.on('change', function () {
      resetPrice();
      changeAnzeigenPrice();
    })

    function resetPrice() {
      multiple_price.removeClass('strikethrough');
      $('.changed-price').remove();
    }

    function getMehrfachschaltungRabatt() {
      var checked = mehrfachbuchung_input.find(':checked').val();
      var rx = /\Schaltungen (\d+)%/g;
      var result = rx.exec(checked);

      return (result && result[1]) ? result[1] : null;
    }

    function getNeukundenRabatt() {
      var checked = neukunden_checkbox.prop('checked');

      return checked ? 15 : null;
    }

    function changeAnzeigenPrice() {
      var price = multiple_price.html().replace('.', '').replace(' €', '');

      var percentage = 0;
      var percentageText = '';

      var mehrfachRabatt = getMehrfachschaltungRabatt();

      if (mehrfachRabatt) {
        percentage = mehrfachRabatt;
        percentageText += ' sowie ' + percentage + '% Rabatt auf Ihre Mehrfachbuchung ';
      }

      var neukundenRabatt = getNeukundenRabatt();

      if (neukundenRabatt) {
        percentageText += ' und ' + neukundenRabatt + '% Neukundenrabatt ';
        percentage = percentage === 0 ? neukundenRabatt : parseInt(percentage) + parseInt(neukundenRabatt);
      }

      price = parseFloat(price) * (1 - percentage / 100);

      multiple_price.after('<span class="changed-price">' + new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2}).format(price) + ' €</span>');

      $('#gfield_description_48_5').append('<span class="changed-price">' + percentageText + '</span>');

      multiple_price.addClass('strikethrough');
    }
  },
}

$('#gform_fields_48').click(function () {
  $('#field_48_59').css('display', 'flex');
});
