/*eslint indent: [2, 2]*/
/*globals $ */

// eslint-disable-next-line
function getUrlParameter(name) {
  // eslint-disable-next-line
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function debounce(callback, delay) {
  var timeout
  return function () {
    var args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      callback.apply(this, args)
    }.bind(this), delay)
  }
}

(function() {
  'use strict';

  $('.band-excerpt').each(function(){
    var toggleText = $(this).find('.toggleText');
    var toggleElem = $(this).find('.hiddenText');
    var hideElem = $(this).find('.hideText');
    var elipsis = $(this).find('.elipsis');

    hideElem.click(function (event) {
      event.preventDefault();
      toggleElem.slideToggle(200, function () {
        toggleText.css('display', 'inline-block');
        elipsis.css('display', 'inline-block');
      });
    });

    toggleText.click(function(event){
      event.preventDefault();
      $(this).css('display', 'none');

      toggleElem.slideToggle(200, function () {
        $(this).css('display', 'inline');
        elipsis.css('display', 'none');
      });
    })
  })

  $('.expand-list').each(function(){
    let that = this;
    let toggle = $(this).find('.toggle-list');

    toggle.click(function(event){
      event.preventDefault();
      $(that).find('.expand-item').removeClass('hidden')
      $(this).addClass('hidden');
    })
  })

  // search
  $('.search-tab-link').on('click', function (event) {
    event.preventDefault();
    var query = getUrlParameter('query');
    var pathname = $(this).attr('href');
    var parameters = { query };
    window.location.href = `${window.location.origin}${pathname}?${$.param(parameters)}`;
  });

  // search images
  $('#search-image-submit-query').on('click', function (event) {
    event.preventDefault();
    var query = $('#search-image-text-query').val();
    var parameters = { query };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // search articles
  // $('#search-submit-query').on('click', function (event) {
  //   event.preventDefault();
  //   var query = $('#search-text-query').val();
  //   var issue = getUrlParameter('issue');
  //   var parameters = { query, issue};
  //   window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  // });

  $('#search-filter-issue').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var issue = value === 'default' ? '' : value;
    var query = getUrlParameter('query');
    var rubrik = getUrlParameter('rubrik');
    var sorting = getUrlParameter('sorting');
    var parameters = { issue, query, sorting, rubrik };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });


  $('#search-sorting').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var sorting = value;
    var query = getUrlParameter('query');
    var issue = getUrlParameter('issue');
    var rubrik = getUrlParameter('rubrik');
    var parameters = { sorting, rubrik, issue, query };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });


  $('#search-filter-rubrik').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var rubrik = value;
    var issue = getUrlParameter('issue');
    var query = getUrlParameter('query');
    var sorting = getUrlParameter('sorting');
    var parameters = { query, rubrik, issue, sorting };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });
  // research large exhibition
  $('#research-largeexhibitions-submit-query').on('click', function (event) {
    event.preventDefault();
    var query = $('#research-largeexhibitions-text-query').val();
    var country = getUrlParameter('country');
    var group = getUrlParameter('group');
    var parameters = { country, query, group};
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // research large exhibition
  $('#research-largeexhibitions-submit-query').on('click', function (event) {
    event.preventDefault();
    var query = $('#research-largeexhibitions-text-query').val();
    var country = getUrlParameter('country');
    var group = getUrlParameter('group');
    var y = getUrlParameter('y');
    var parameters = { country, query, group, y};
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // research institution
  $('#research-institution-submit-query').on('click', function (event) {
    event.preventDefault();
    var query = $('#research-institution-text-query').val();
    var place = getUrlParameter('place');
    var country = getUrlParameter('country');
    var parameters = { country, query, place};
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // $('#research-filter-place').on('changed.bs.select', function (event) {
  //   event.preventDefault();
  //   var value = $(event.target).val();
  //   var place = value === 'default' ? '' : value;
  //   var country = getUrlParameter('country');
  //   var query = getUrlParameter('query');
  //   var parameters = { country, query, place};
  //   window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  // });


  $('#person-filter-rubrik').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var rubrik = value;
    var issue = getUrlParameter('issue');
    var parameters = { rubrik, issue };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // $('#person-filter-issue').on('changed.bs.select', function (event) {
  //   event.preventDefault();
  //   var value = $(event.target).val();
  //   var issue = value;
  //   var rubrik = getUrlParameter('rubrik');
  //   var parameters = { rubrik, issue };
  //   window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  // });

  $('#research-filter-inst-band').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var issue = value;
    var sorting = getUrlParameter('sorting');
    var parameters = { sorting, issue };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  $('#research-filter-grossauss-band').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var issue = value;
    var sorting = getUrlParameter('sorting');
    var parameters = { sorting, issue };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // $('#grossauss-search-sorting').on('changed.bs.select', function (event) {
  //   event.preventDefault();
  //   var value = $(event.target).val();
  //   var sorting = value;
  //   var issue = getUrlParameter('issue');
  //   var parameters = { sorting, issue };
  //   window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  // });



  // research person
  // $('.person_search_results .more').on('click', function(event) {
  //   event.preventDefault();
  //   var indexCharSelector = $(this).data("indexCharSelector");
  //   $(`.${indexCharSelector}`).css("display", "block");
  //   $(this).remove();
  // });

  // filter Inst

  $('#research-artikel-text-query').on('input', debounce(function () {
    filterArtikel($(this).val());
  }, 500));

  $('#research-artikel-submit-query').on('click', function (event) {
    event.preventDefault();
    var filter = $('#research-artikel-text-query').val();
    filterArtikel(filter);
  });

  function filterArtikel(filter) {
    $('.personen-artikel .col-sm-6').hide(0);

    $('.personen-artikel .col-sm-6').each(function () {
      var html = $(this).text().toLowerCase();
      var match = html.indexOf(filter.toLowerCase()) > -1
      if (match) {
        $(this).show(0)
      }
    })

  }

  $('#inst-single-sorting').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var sorting = value;
    var issue = getUrlParameter('issue');
    var parameters = { sorting, issue};
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  $('#grossauss-single-sorting').on('changed.bs.select', function (event) {
    event.preventDefault();
    var value = $(event.target).val();
    var sorting = value;
    var issue = getUrlParameter('issue');
    var parameters = { sorting, issue };
    window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  });

  // $('#research-filter-issue').on('changed.bs.select', function (event) {
  //   event.preventDefault();
  //   var value = $(event.target).val();
  //   var issue = value === 'default' ? '' : value;
  //   var profession = getUrlParameter('profession');
  //   var query = getUrlParameter('query');
  //   var parameters = { profession, issue, query};
  //   window.location.href = `${window.location.origin}${window.location.pathname}?${$.param(parameters)}`;
  // });

  const ajaxurl = window.global_object.ajaxurl || false;

  $(document).on('click', '.navbar-collapse .nav-item.hasItems .nav-link', function() {
    $(this).parent().toggleClass('open');
  });

  if($('form#gform_44').length) {
    const aboForm        = $('form#gform_44');
    const queryString = window.location.search;
    const urlParams   = new URLSearchParams(queryString);

    const bandnummer  = urlParams.get('bandnummer');
    if(bandnummer) {
      var aboIssues  = aboForm.find('.bih-field input[type="radio"]');

      aboIssues.each(function() {
        if($(this).val().startsWith(bandnummer)) {
          $(this).prop('checked', true);
        }
      });
    }
  }

  // Popup Band
  $(document).on('click', '.band_popup', function() {
    event.preventDefault();

    $.ajax({
      type: 'POST',
      url: ajaxurl,
      data: {
        action: 'modal_band',
        band: $(this).data('band'),
      },
      success:function(response){
        $('.remodal-content').html(response);
        var remodal       = $('[data-remodal-id=modal]').remodal({
          hashTracking: false,
        });
        var remodalState  = remodal.getState();
        if(remodalState == 'closed'){
          remodal.open();
        }
      },
    });
  });
})();
