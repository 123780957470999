import urlParams from '../util/urlParams';

export default {
  init() {
    // JavaScript to be fired on the page

    let $filterForm = $('.filter-nachrichten')

    $filterForm.find('select').each((idx, filter) => {
      $(filter).on('change', function () {
        let serial = $filterForm.serialize()
        window.location.search = `?${serial}`
      })
    })
  },
  finalize() {
    // JavaScript to be fired on the page, after the init JS
    let params = urlParams(window.location.href)

    let scrollAfterLoad = false;
    // selecting filter from url
    for (let k in params) {
      $(`select[name="${k}"] option[value="${params[k]}"]`).prop('selected', true)
      if ('pg' == k) {
        scrollAfterLoad = true;
      } else {
        if ( params[k].length > 0 ) {
          scrollAfterLoad = true;
        }
      }
    }

    // scroll to nachrichten if filter was changed
    if(scrollAfterLoad)
      $(window).scrollTop(
        $('.nachrichten-wrapper').offset().top
      )

    $(window).scroll(function(){
      let w = $(window).width();

      if (w > 768){
        $('.sidebar-newsletter-holder').append( $('.anzeige-newsletter') )
      }else{
        $('.inline-newsletter-holder').append( $('.anzeige-newsletter') )
      }
    })

  },
};
